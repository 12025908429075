import React from 'react'
import PageCarousel from '../Carousel';
import '../custom.css';
import Iframe from 'react-iframe';
import ReactPlayer from 'react-player'
import { Parallax, Background } from 'react-parallax';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCoffee , faBook, faHammer, faObjectGroup, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import HeaderImage from '../HeaderImage';

library.add(faCoffee);




const HomePage = () => {
    return (
        <div >
            <HeroImage
                headerImageTitle="Web Developer for Hire"
            />

            <HomeIconSection />

            <MainSection />

            <EmailBottom />
        </div>
    );
};

const HeroImage = (props) => {
    let imageSource = props.headerImageSource;
    String(imageSource);
    return (
        <Parallax
            bgImage={require("../img/processed/photoshop_assignment.png")}
            strength={400}
            renderLayer={percentage => (
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        width: percentage * 500,
                        height: percentage * 500,
                    }}
                />
            )}
        >
            <div className="text-light center-text-v" style={{ height: '450px' }} >
                <div className="">
                    <h1 className="">{props.headerImageTitle}</h1>
                    <p className="">{props.headerImageText}</p>
                </div>
            </div>
        </Parallax>
    );
};


const HomeIconSection = () => {
    return (
        <div>
            <div id="home-icons" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4 text-center">
                            <FontAwesomeIcon icon={faHammer} />
                            <h3>Building Websites</h3>
                        </div>
                        <div className="col-md-4 mb-4 text-center">
                            <FontAwesomeIcon icon={faBook} />
                            <h3>If I don't know it, I'll find it!</h3>
                        </div>
                        <div className="col-md-4 mb-4 text-center">
                            <FontAwesomeIcon icon={faObjectGroup} />
                            <h3>Site Optimization</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GoogleMap = () => {
    return (
        <div>
            <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d642620.5839694977!2d-114.63457563743555!3d51.01177665681793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537170039f843fd5%3A0x266d3bb1b652b63a!2sCalgary%2C+AB!5e0!3m2!1sen!2sca!4v1544508732562"
                width="100%"
                height="450px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen
            />
        </div>
    );
};

const MainSection = () => {
    return (
        <div>
            <Parallax
                blur={{ min: -15, max: 15 }}
                bgImage={require('../img/processed/annie-spratt-671739-unsplash.jpg')}
                bgImageAlt="the dog"
                strength={-200}
            >
                <div className="center-text-v text-light dark-occupancy" style={{ height: '200px' }} >
                    <h1 className="">
                        This is how you'll feel when you hire me
                    </h1>
                    <div className="">
                        <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                </div>
            </Parallax>

            <div className="my-3"/>

            <div className="container">
                <ReactPlayer url='https://www.youtube.com/watch?v=FQrKSf-Nk-Q' width="100%" />
                <div className="text-center">
                    <a className="text-dark" href="https://soundcloud.com/yt-audio-library">
                        <h1 className="text-dark py-3 float-none">
                            <FontAwesomeIcon icon={faArrowUp} className="" />
                            <span className="px-3">Copy Right Free Music</span>
                            <FontAwesomeIcon icon={faArrowUp} className="" />
                        </h1>

                    </a>
                </div>
            </div>

            <div className="my-3"/>

            <Parallax
                className="dark-occupancy "
                blur={10}
                bgImage={require('../img/processed/clement-h-544786-unsplash.jpg')}
                bgImageAlt="the cat"
                strength={200}
            >
                <div className="center-text-v text-light dark-occupancy" style={{ height: '200px' }} />
            </Parallax>

            <div className="my-4">
                <h1 className="container text-center pb-4">Location</h1>
                <GoogleMap/>
            </div>

            <Parallax
                bgImage={require('../img/processed/patrick-dzieza-1172000-unsplash.jpg')}
                strength={400}
                renderLayer={percentage => (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            width: percentage * 500,
                            height: percentage * 500,
                        }}
                    />
                )}
            >
                <div className="center-text-v text-light dark-occupancy pt-4" style={{ height: '200px' }} />
            </Parallax>
        </div>
    );
};


const EmailBottom = () => {
    return (
        <div>
            <div className="text-center p-5 bg-dark text-white">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1>Email Me</h1>
                            <form action="https://mailthis.to/bdvpow@gmail.com"
                                  method="POST"
                                  className="form-inline justify-content-center"
                            >
                                <input
                                    type="text"
                                    className="form-control mb-2 mr-2"
                                    name="Name"
                                    placeholder="Enter Name"
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2 mr-2"
                                    name="Reply to"
                                    placeholder="Enter Email"
                                />
                                <button type="submit" className="btn btn-primary mb-2">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                <a className="text-light no-underline" href="mailto:bdvpow@gmail.com?Subject=BDavez%20Website"
                   target="_top">Or Send an Email Directly Here</a>
            </div>
        </div>
    );
};

export default HomePage;