import React from 'react';
import { Parallax, Background } from 'react-parallax';
import '../custom.css';
import HeaderImage from "../HeaderImage";

const AboutPage = () => {
    return(
        <div className="">
            <HeaderImage
                headerImageTitle="About"
            />

            <div className="container mt-5 mb-5 v-fill">
                <h1 className="text-center pb-4">About Brendan Davis</h1>
                <p>
                    I am a computer enthusiast. My first projects began in middle school and I have been intrigued and fascinated with the process ever since. Depending on the project, I will either start with how it looks or how it functions.
                    Generally, when creating a static website, I prefer to get the structure working first and then incorporate the stylistics.
                    For front-end frameworks (in a time sensitive situation) I like using bootstrap.
                </p>
                <p>
                    Another front-end framework that I have used for basic websites is ReactJS. I enjoy the fact that it has a large community with a lot of support and within that community it is very easy to incorporate other people’s NPM packages and components. Also, ReactJS has a “write once” philosophy where it is very easy to reuse code.
                </p>
                <p>
                    For frameworks with back-end I have experience with Laravel. I start with the views and then the controller and models with migrations.
                    Overall, I am motivated as a Web Developer by the satisfaction that comes from creating something that is visually appealing, is functional and will allow me to pay my bills.
                </p>
            </div>
        </div>
    );
};

export default AboutPage;