import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import './custom.css';
import imageOceanShore from './img/processed/annie-spratt-671739-unsplash.jpg';
import imageCodeScreen from './img/processed/chris-ried-512801-unsplash.jpg';
import imageCodeMac from './img/processed/clement-h-544786-unsplash.jpg';
import imageAppleMac from './img/processed/michal-kubalczyk-505207-unsplash.jpg';
import imageForest from './img/processed/patrick-dzieza-1172000-unsplash.jpg';


const items = [
    {
        src: imageCodeMac,
    },
    {
        src: imageCodeScreen,
    },
    {
        src: imageAppleMac,
    }
];

const PageCarousel = () => <UncontrolledCarousel items={items} />;

export default PageCarousel;