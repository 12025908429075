import React from 'react';

const Form = () => {
    return (
        <div>
            <form action="https://mailthis.to/bdvpow@gmail.com" method="POST" encType="multipart/form-data">
                <h3>Contact Form</h3>

                <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-2 col-form-label">Name</label>
                    <div className="col-10">
                        <input className="form-control" type="text" name="name" placeholder="Your name" id="example-text-input"/>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="example-email-input" className="col-2 col-form-label">Email</label>
                    <div className="col-10">
                        <input className="form-control" type="email" name="email" placeholder="you@mail.com" id="example-email-input"/>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="example-tel-input" className="col-2 col-form-label">Telephone</label>
                    <div className="col-10">
                        <input className="form-control" type="tel" placeholder="1-(555)-555-5555" id="example-tel-input"/>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleTextarea">Message</label>
                    <textarea className="form-control" name="message" placeholder="Enter your message here" id="exampleTextarea" rows="3"/>
                </div>

                <input type="hidden" name="_subject" value="Contact form submitted"/>

                <input type="hidden" name="_replyto" value=""/>

                <input type="hidden" name="_after" value="/"/>

                <input type="hidden" name="_confirmation" value=""/>

                <input type="submit" name="submit" value="Submit" className="btn btn-primary"/>
            </form>
        </div>
    );
};

export default Form;