import React from 'react';
import ReactCard from '../CardComponent';
import HeaderImage from '../HeaderImage';
import { Container, Row, Col } from 'reactstrap';
import '../custom.css';
import bdvTravel from '../img/portfolio-websites/bdv-travel.png';
import cgLaw from '../img/portfolio-websites/cg-law-llp.png';
import evolvingIT from '../img/portfolio-websites/evolving-it.png';
import lyricGrabber from '../img/portfolio-websites/lyric-grabber.png';
import renewSoul from '../img/portfolio-websites/renewsoul-laravel-crud.png';
import bdavezPortfolio from '../img/portfolio-websites/bdavez-portfolio-static.png';





const PortfolioPage = () => {
    let imgSource = "https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180";
    return(
        <div>
            <HeaderImage
                headerImageTitle="Portfolio"
            />

            <Container fluid>
                <div className="mt-3">
                    <h1 className="text-center">Projects</h1>
                </div>
                <Row>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={bdvTravel}
                            cardTitle="Evolving-IT"
                            cardSubtitle="A responsive website."
                            cardButtonText="Go to Project"
                            buttonLink="https://evolving-it.ca/"
                        />
                    </Col>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={cgLaw}
                            cardTitle="Lyric Grabber"
                            cardSubtitle="A JSON API Application."
                            cardButtonText="Go to Project"
                            buttonLink="https://lyricgrabber.evolving-it.ca/"
                        />
                    </Col>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={evolvingIT}
                            cardTitle="RenewSoul"
                            cardSubtitle="A Laravel web application."
                            cardButtonText="Go to Project"
                            buttonLink="http://13.58.232.241/posts"
                        />
                    </Col>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={lyricGrabber}
                            cardTitle="CG Law LLP"
                            cardSubtitle="A Wordpress website."
                            cardButtonText="Go to Project"
                            buttonLink="http://35.193.153.137/"
                        />
                    </Col>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={renewSoul}
                            cardTitle="BDV Travel"
                            cardSubtitle="JavaScript based Travel Website"
                            cardButtonText="Go to Project"
                            buttonLink="http://demo.bdavez.com"
                        />
                    </Col>
                    <Col className="my-3" lg="3" sm="6">
                        <ReactCard
                            buttonTextColor="text-light"
                            buttonColor="primary"
                            cardImgSource={bdavezPortfolio}
                            cardTitle="BDavez"
                            cardSubtitle="Portfolio Website"
                            cardButtonText="Go to Project"
                            buttonLink="https://bdavez.com/"
                        />
                    </Col>
                </Row>
            </Container>
            <div className="my-5"/>
        </div>
    );
};

export default PortfolioPage;