import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './components/webpages/Home';
import PortfolioPage from './components/webpages/Portfolio';
import ContactPage from './components/webpages/Contact';
import AboutPage from './components/webpages/About';
import ApiPage from './components/webpages/Services/Api';
import ServicesOverviewPage from './components/webpages/Services';
import PhpSqlPage from './components/webpages/Services/PhpSql';
import ReactPage from './components/webpages/Services/React';
import ResumePage from './components/webpages/Services/Resume';


class App extends Component {
  render() {
    return (
        <div>
            <BrowserRouter>
                <div>
                    <Header/>
                    <div>
                        <Route path="/" exact component={HomePage}/>
                        <Route path="/portfolio" component={PortfolioPage}/>
                        <Route path="/contact" component={ContactPage}/>
                        <Route path="/about" component={AboutPage}/>
                        <Route path="/services/" exact component={ServicesOverviewPage}/>
                    </div>
                    <Footer />
                </div>
            </BrowserRouter>
        </div>
    );
  }
}

export default App;
