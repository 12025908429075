import React from 'react';
import { Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button } from 'reactstrap';

const ReactCard = (props) => {
    return (
        <div>
            <Card>
                <CardImg top width="100%" src={props.cardImgSource} alt="Card image cap" />
                <CardBody>
                    <CardTitle>{props.cardTitle}</CardTitle>
                    <CardSubtitle>{props.cardSubtitle}</CardSubtitle>
                    <CardText>{props.cardText}</CardText>
                    <Button color={props.buttonColor}>
                        <a className={props.buttonTextColor} href={props.buttonLink}>{props.cardButtonText}</a>
                    </Button>
                </CardBody>
            </Card>
        </div>
    );
};

export default ReactCard;