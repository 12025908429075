import React from 'react';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import HeaderImage from '../HeaderImage';
import { Link } from 'react-router-dom';

import {
    Card,
    CardImg,
    CardText,
    CardBody,
    CardLink,
    CardTitle,
    CardSubtitle,
    Row,
    Col,
    Container
} from 'reactstrap';

import { faCoffee, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ServicesCard = (props) => {
    return (
        <div>
            <Card>
                <CardBody className="bg-dark text-light">
                    <CardTitle className="text-center"><h1>{props.serviceNumber}</h1></CardTitle>
                </CardBody>
                <CardBody>
                    <CardTitle className="text-center"><h1>{props.monthlyRate}</h1></CardTitle>
                </CardBody>
                <ListGroup>
                    <ListGroupItem className="text-center">
                        {props.featureOne}
                        <span className="pr-4 float-right"><FontAwesomeIcon icon={props.serviceIcon1} /></span>
                    </ListGroupItem>
                    <ListGroupItem className="text-center">
                        {props.featureTwo}
                        <span className="pr-4 float-right"><FontAwesomeIcon icon={props.serviceIcon2} /></span>
                    </ListGroupItem>
                    <ListGroupItem className="text-center">
                        {props.featureThree}
                        <span className="pr-4 float-right"><FontAwesomeIcon icon={props.serviceIcon3} /></span>
                    </ListGroupItem>
                    <ListGroupItem className="text-center">
                        {props.featureFour}
                        <span className="pr-4 float-right"><FontAwesomeIcon icon={props.serviceIcon4} /></span>
                    </ListGroupItem>
                    <ListGroupItem className="text-center">
                        <Link to="/contact" className="btn btn-danger btn-block mt-2">Get It</Link>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        </div>
    );
};



const ServicesOverviewPage = () => {
    return (
        <div>
            <HeaderImage
                headerImageTitle="Services"
            />
            <div className="my-4">
                <h1 className="text-center">Rates</h1>
            </div>
            <Container className="">
                <Row>
                    <Col className="my-3" md="4">
                        <ServicesCard
                            serviceNumber="Serivce 1"
                            monthlyRate="$60.00/Hour"
                            featureOne="Wire Frame MockUps"
                            featureTwo="Web Development"
                            featureThree="Client Side Scripting"
                            featureFour="Server Side Scripting"
                            serviceIcon1={faCheck}
                            serviceIcon2={faTimes}
                            serviceIcon3={faTimes}
                            serviceIcon4={faTimes}
                        />
                    </Col>
                    <Col className="my-3" md="4">
                        <ServicesCard
                            serviceNumber="Serivce 2"
                            monthlyRate="$80.00/Hour"
                            featureOne="Wire Frame MockUps"
                            featureTwo="Web Development"
                            featureThree="Client Side Scripting"
                            featureFour="Server Side Scripting"
                            serviceIcon1={faCheck}
                            serviceIcon2={faCheck}
                            serviceIcon3={faTimes}
                            serviceIcon4={faTimes}
                        />
                    </Col>
                    <Col className="my-3" md="4">
                        <ServicesCard
                            serviceNumber="Serivce 3"
                            monthlyRate="$100.00s/Hour"
                            featureOne="Wire Frame MockUps"
                            featureTwo="Web Development"
                            featureThree="Client Side Scripting"
                            featureFour="Server Side Scripting"
                            serviceIcon1={faCheck}
                            serviceIcon2={faCheck}
                            serviceIcon3={faCheck}
                            serviceIcon4={faCheck}
                        />
                    </Col>
                </Row>
            </Container>
            <div className="my-5"/>
        </div>
    );
};

export default ServicesOverviewPage;