import React from 'react'
import PageForm from '../Form';
import '../custom.css';

const ContactPage = () => {
    return(
        <div className="container v-fill mt-5">
            <PageForm/>
        </div>
    );
};

export default ContactPage;