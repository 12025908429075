import { Parallax, Background } from 'react-parallax';
import React from "react";


const HeaderImage = (props) => {
    let imageSource = props.headerImageSource;
    String(imageSource);
    return (
        <Parallax
            bgImage={require("./img/processed/patrick-dzieza-1172000-unsplash.jpg")}
            strength={400}
            renderLayer={percentage => (
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        width: percentage * 500,
                        height: percentage * 500,
                    }}
                />
            )}
        >
            <div className="text-light center-text-v" style={{ height: '15rem' }} >
                <div className="">
                    <h1 className="">{props.headerImageTitle}</h1>
                    <p className="">{props.headerImageText}</p>
                </div>
            </div>
        </Parallax>
    );
};

export default HeaderImage;