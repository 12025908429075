import React from 'react';
import './custom.css';

const Footer = () => {
    return(
        <footer className="footer bg-pitch-black">
            <div className="container">
                <span className="text-muted">&#169; BDavez inc.</span>
            </div>
        </footer>
    );
};

export default Footer;